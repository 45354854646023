@import url(https://fonts.googleapis.com/css?family=Poppins:400,700,900);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow-x: hidden;
  background-color: #e8faff;
  zoom: 0.75;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiPaper-root.MuiMenu-paper {
  left: 32px !important;
  top: 295px !important;
}

header {
    margin: 0;
    padding: 0;
}

.Header_padding_0__2uzBZ {
    padding-right: 0;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Header_header_container__3iL8x {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: center;
}

.Header_header_logo__-OnNl p {
    font-size: 1.35rem;
    line-height: 0.9em;
    color: #f49f55;
    margin: 0;
}

.Header_header_logo__-OnNl img {
    width: 200px;
}

.Header_header_title__5Ot2q {
    font-size: 7rem;
    font-weight: 200;
    margin: 0;
    color: #6ebcd5;
    line-height: 0.9em;
    padding-top: 12px;
}

.Header_header_subtitle__U-yyf {
    min-width: 675px;
    font-size: 2.75rem;
    line-height: 0.9em;
    font-weight: 350;
    margin: 0;
    color: #f49646;
    display: contents;
}

.Header_last_title__seYD3 {
    min-width: 1200px;
    line-height: 1em;
    font-size: 3.7rem;
    font-weight: 400;
    color: #347eab;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width:1200px) and (min-width: 991.9px) {
    .Header_last_title__seYD3 {
        min-width: 900px;
        line-height: 1em;
        font-size: 3.5rem;
        font-weight: 400;
        color: #347eab;
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 991.9px) and (min-width: 768px) {
    .Header_header_logo__-OnNl p {
        font-size: 1rem;
        line-height: 0.9em;
        color: #f49f55;
        margin: 0;
    }
    .Header_header_logo__-OnNl img {
        width: 150px;
    }
    .Header_header_title__5Ot2q {
        font-size: 5.5rem;
        font-weight: 200;
        margin: 0;
        color: #6ebcd5;
        line-height: 0.9em;
        padding-top: 12px;
    }
    .Header_header_subtitle__U-yyf {
        min-width: 675px;
        font-size: 2rem;
        line-height: 0.9em;
        font-weight: 350;
        margin: 0;
        color: #f49646;
        display: contents;
    }
    .Header_last_title__seYD3 {
        min-width: 750px;
        line-height: 1em;
        font-size: 2.75rem;
        font-weight: 400;
        color: #347eab;
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
    .Header_header_logo__-OnNl p {
        font-size: 0.8rem;
        line-height: 0.9em;
        color: #f49f55;
        margin: 0;
    }
    .Header_header_logo__-OnNl img {
        width: 110px;
    }
    .Header_header_title__5Ot2q {
        font-size: 4rem;
        font-weight: 200;
        margin: 0;
        color: #6ebcd5;
        line-height: 0.9em;
        padding-top: 8px;
    }
    .Header_header_subtitle__U-yyf {
        min-width: 675px;
        font-size: 1.6rem;
        line-height: 0.9em;
        font-weight: 350;
        margin: 0;
        color: #f49646;
        display: contents;
    }
    .Header_last_title__seYD3 {
        min-width: 550px;
        line-height: 1em;
        font-size: 2rem;
        font-weight: 400;
        color: #347eab;
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 576px) {
    .Header_header_logo__-OnNl p {
        font-size: 0.4rem;
        line-height: 0.7em;
        color: #f49f55;
        margin: 0;
    }
    .Header_header_logo__-OnNl img {
        width: 75px;
        padding-top: 10px;
    }
    .Header_header_title__5Ot2q {
        font-size: 2rem;
        font-weight: 200;
        margin: 0;
        color: #6ebcd5;
        line-height: 0.9em;
        padding-top: 12px;
    }
    .Header_header_subtitle__U-yyf {
        min-width: 300px;
        font-size: 1rem;
        line-height: 0.9em;
        font-weight: 350;
        margin: 0;
        color: #f49646;
        display: contents;
    }
    .Header_last_title__seYD3 {
        min-width: 300px;
        line-height: 1em;
        font-size: 1.2rem;
        font-weight: 400;
        color: #347eab;
        display: flex;
        justify-content: center;
    }
}
.MainImage_main_image__1BmT_ {
    display: flex;
    justify-content: center;
}

.MainImage_main_image__1BmT_ img {
    width: 1200px;
    margin-bottom: 80px;
    object-fit: cover;
}

@media only screen and (max-width:1200px) and (min-width: 991.9px) {
    .MainImage_main_image__1BmT_ img {
        width: 900px;
        margin-bottom: 70px;
    }
}

@media only screen and (max-width: 991.9px) and (min-width: 768px) {
    .MainImage_main_image__1BmT_ img {
        width: 700px;
        margin-bottom: 60px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
    .MainImage_main_image__1BmT_ img {
        width: 500px;
        margin-bottom: 60px;
    }
}

@media only screen and (max-width: 576px) {
    .MainImage_main_image__1BmT_ img {
        width: 250px;
        margin-bottom: 60px;
    }
}
/* span {
    margin: 0 0.5rem;
} */

.CalcForm_ccc__3lLCx:hover {
    cursor: pointer;
}

.CalcForm_ww__Qw1mJ {
    width: 400px;
}

label {
    justify-content: center;
    display: flex;
    color: #9094a1;
    font-size: 1.2rem;
}

.CalcForm_st__2vnJN {
    position: static;
    margin-bottom: 11px;
}

.CalcForm_calc_title__3uXjP {
    /* min-width: 1200px; */
    color: #347eab;
    font-size: 25px;
    line-height: 1em;
}

.CalcForm_form_container__1kYws {
    /* min-width: 1300px; */
    width: 1100px;
    padding: 20px;
    border-radius: 30px;
    margin: 0 20px 70px 20px;
    background-color: white;
    zoom: 1.2;
}

.CalcForm_input_border__1Ghgz {
    border: none;
    outline: none;
    border-bottom: 2px solid #ebebeb;
    height: 50px;
    width: 100%;
    text-align-last: center;
}

.CalcForm_input_border__1Ghgz::-webkit-input-placeholder {
    color: black;
}

.CalcForm_input_border__1Ghgz:-ms-input-placeholder {
    color: black;
}

.CalcForm_input_border__1Ghgz::placeholder {
    color: black;
}

.CalcForm_calc_button__32ba5 {
    display: flex;
    justify-content: center;
    border: none;
    color: white;
    background-color: #008037;
    width: 100%;
    font-size: 20px;
    padding: 10px 35px;
}

@media only screen and (max-width:1200px) and (min-width: 991.9px) {
    .CalcForm_marg__1arwl {
        margin: 10px 0;
    }
    .CalcForm_calc_title__3uXjP {
        color: #347eab;
        font-size: 20px;
        line-height: 1em;
    }
    .CalcForm_form_container__1kYws {
        /* min-width: 1300px; */
        width: 950px;
        padding: 20px;
        border-radius: 30px;
        margin: 0 20px 70px 20px;
        background-color: white;
    }
    .CalcForm_ww__Qw1mJ {
        width: 375px;
    }
}

@media only screen and (max-width: 991.9px) and (min-width: 768px) {
    .CalcForm_calc_title__3uXjP {
        color: #347eab;
        font-size: 15px;
        line-height: 1em;
    }
    .CalcForm_mb__2pL7Q {
        margin-bottom: 30px;
    }
    .CalcForm_date_con__5kLSr {
        width: 100%;
        height: 50px;
        border-bottom: 2px solid #ebebeb;
    }
    .CalcForm_form_container__1kYws {
        min-width: 550px;
        width: 35%;
        padding: 20px;
        border-radius: 30px;
        margin-bottom: 8vh;
        background-color: white;
    }
    .CalcForm_input_border__1Ghgz {
        border: none;
        outline: none;
        border-bottom: 2px solid #ebebeb;
        height: 50px;
        width: 240px;
    }
    .CalcForm_date_con__5kLSr {
        width: 240px;
        height: 50px;
        border-bottom: 2px solid #ebebeb;
    }
    .CalcForm_calc_button__32ba5 {
        border: none;
        color: white;
        background-color: #008037;
        width: 100%;
        font-size: 20px;
        margin: 25px 0px 15px;
        padding: 10px 35px;
    }
    .CalcForm_ww__Qw1mJ {
        width: 300px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
    .CalcForm_calc_title__3uXjP {
        color: #347eab;
        font-size: 13px;
        line-height: 1em;
    }
    .CalcForm_mb__2pL7Q {
        margin-bottom: 15px;
    }
    label {
        justify-content: center;
        display: flex;
        color: #9094a1;
        font-size: 22px;
        margin: 0;
    }
    .CalcForm_marg__1arwl {
        margin: 15px 0;
    }
    .CalcForm_date_con__5kLSr {
        width: 100%;
        height: 50px;
        border-bottom: 2px solid #ebebeb;
    }
    .CalcForm_form_container__1kYws {
        /* min-width: 300px; */
        width: 70%;
        padding: 20px;
        border-radius: 30px;
        margin-bottom: 8vh;
        background-color: white;
    }
    .CalcForm_calc_button__32ba5 {
        border: none;
        color: white;
        background-color: #008037;
        width: 100%;
        font-size: 20px;
        margin: 25px 0px 15px;
        padding: 10px 35px;
    }
    .CalcForm_ww__Qw1mJ {
        width: 300px;
    }
}

@media only screen and (max-width: 576px) {
    .CalcForm_calc_title__3uXjP {
        color: #347eab;
        font-size: 13px;
        line-height: 1em;
    }
    .CalcForm_form_container__1kYws {
        width: 300px;
        padding: 20px;
        border-radius: 30px;
        margin: 0 20px 70px 20px;
        background-color: white;
    }
    .CalcForm_tit__36dW7 {
        padding: 0px 30px;
    }
    .CalcForm_ww__Qw1mJ {
        width: 250px;
    }
}
audio::-webkit-media-controls-timeline,
video::-webkit-media-controls-timeline {
    display: none;
}
audio::-webkit-media-controls,
video::-webkit-media-controls {
    display: none;
}


.VideoContainer_vvv__30nhc {
    width: 1355px;
    margin: 0 20px;
}

.VideoContainer_video_container__27FzJ {
    margin-bottom: 80px;
    margin-top: 40px;
}

@media only screen and (max-width:1200px) and (min-width: 991.9px) {
    .VideoContainer_vvv__30nhc {
        width: 1000px;
    }
}

@media only screen and (max-width: 991.9px) and (min-width: 768px) {
    .VideoContainer_vvv__30nhc {
        width: 800px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
    .VideoContainer_vvv__30nhc {
        width: 500px;
    }
}

@media only screen and (max-width: 576px) {
    .VideoContainer_vvv__30nhc {
        width: 250px;
    }
    .VideoContainer_video_container__27FzJ {
        margin-bottom: 80px;
        margin-top: 20px;
    }
}
.Content_wraper__2wKH3 {
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
}

.Content_content__mFJ5h {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 1180px;
    padding: 0 10px;
}

/* .main_image img {
    width: 70%;
    margin-bottom: 8vh;
} */

.Content_site_content__2Fsq_ {
    padding: 0;
}

.Content_site_content__2Fsq_ h2 {
    font-size: 2rem;
    font-weight: 650;
    color: #347eab;
    margin-bottom: 30px;
}

.Content_site_content__2Fsq_ p {
    font-size: 1.5rem;
    color: #347eab;
    font-weight: 400;
}

.Content_content_img__hmha3 {
    width: 500px;
}

.Content_imgContainer__9Ix3M {
    display: flex;
    justify-content: center;
}

/* @media only screen and (max-width: 991.9px) and (min-width: 200px) {
    .site_content p {
        font-size: 3vw;
        color: #347eab;
        font-weight: 400;
    }
    .site_content h2 {
        font-size: 4vw;
        font-weight: 650;
        color: #347eab;
        margin-bottom: 3vh;
        margin-top: 4vh;
    }
    .imgContainer {
        display: flex;
        justify-content: center;
        margin-bottom: 5vh;
    }
    .content_img {
        width: 50vw;
    }
} */

@media only screen and (max-width:1200px) and (min-width: 991.9px) {
    .Content_content__mFJ5h {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 900px;
        padding: 0 10px;
    }
    .Content_site_content__2Fsq_ h2 {
        font-size: 1.7rem;
        font-weight: 650;
        color: #347eab;
        margin-bottom: 30px;
    }
    .Content_site_content__2Fsq_ p {
        font-size: 1.1rem;
        color: #347eab;
        font-weight: 400;
    }
    .Content_content_img__hmha3 {
        width: 450px;
    }
}

@media only screen and (max-width: 991.9px) and (min-width: 768px) {
    .Content_content__mFJ5h {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 850px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
    .Content_content__mFJ5h {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 600px;
    }
    .Content_site_content__2Fsq_ h2 {
        font-size: 1.4rem;
        font-weight: 650;
        color: #347eab;
        margin-bottom: 30px;
    }
    .Content_site_content__2Fsq_ p {
        font-size: 1rem;
        color: #347eab;
        font-weight: 400;
    }
    .Content_content_img__hmha3 {
        width: 400px;
    }
}

@media only screen and (max-width: 576px) {
    .Content_content__mFJ5h {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 300px;
    }
    .Content_site_content__2Fsq_ h2 {
        font-size: 1.1rem;
        font-weight: 650;
        color: #347eab;
        margin-bottom: 30px;
    }
    .Content_site_content__2Fsq_ p {
        font-size: 0.8rem;
        color: #347eab;
        font-weight: 400;
    }
    .Content_content_img__hmha3 {
        width: 300px;
    }
}
.Carusel_carusel_title__2E_Bd {
  text-align: center;
  margin-top: 20px;
}

.Carusel_wraper__3lGaB {
  display: flex;
  justify-content: center;
}

.Carusel_carusel__34ay5 {
  background-color: #6fbdd6;
  border: 5px solid #347eab;
  border-radius: 60px;
  margin-bottom: 100px;
  position: relative;
  width: 1000px;
}

.Carusel_carusel__34ay5::before {
  width: 100%;
  height: 100%;
  content: "";
  background-color: #f49646;
  position: absolute;
  bottom: -20px;
  right: -20px;
  border-radius: 60px;
  z-index: -1;
  border: 5px solid #347eab;
}

.Carusel_carusel_txt__-kUPc {
  padding: 20px;
  padding-right: 100px;
}

.Carusel_carusel__34ay5 p {
  color: white;
  font-size: 1.5rem;
  font-weight: 100;
}

.Carusel_carusel__34ay5 h4 {
  color: white;
  font-size: 2.5rem;
}

.Carusel_carusel_img__3WT_2 {
  border-radius: 50%;
  overflow: hidden;
  width: 125px;
  height: 125px;
}

.Carusel_carusel_img__3WT_2 img {
  object-fit: cover;
}

.Carusel_picker__1NFex {
  padding: 50px 0 50px 20px;
}

.Carusel_picker__1NFex p {
  margin: 0;
}

.Carusel_circle_indicators__Sm-wp li {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.Carusel_circle_indicators__Sm-wp {
  min-width: 50px;
  right: 30px;
  justify-content: center;
  margin: 0;
}

@media only screen and (max-width: 1200px) and (min-width: 991.9px) {
  .Carusel_carusel__34ay5 {
    background-color: #6fbdd6;
    border: 5px solid #347eab;
    border-radius: 60px;
    margin-bottom: 100px;
    position: relative;
    width: 900px;
  }
  .Carusel_carusel__34ay5 h4 {
    color: white;
    font-size: 2.25rem;
  }
  .Carusel_carusel__34ay5 p {
    color: white;
    font-size: 1.25rem;
    font-weight: 100;
  }
}

@media only screen and (max-width: 991.9px) and (min-width: 768px) {
  .Carusel_carusel__34ay5 {
    background-color: #6fbdd6;
    border: 5px solid #347eab;
    border-radius: 60px;
    margin-bottom: 80px;
    position: relative;
    width: 650px;
  }
  .Carusel_carusel__34ay5 h4 {
    color: white;
    font-size: 2rem;
  }
  .Carusel_carusel__34ay5 p {
    color: white;
    font-size: 1rem;
    font-weight: 100;
  }
  .Carusel_carusel_img__3WT_2 {
    border-radius: 50%;
    overflow: hidden;
    width: 100px;
    height: 100px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
  .Carusel_carusel__34ay5 {
    background-color: #6fbdd6;
    border: 4px solid #347eab;
    border-radius: 60px;
    margin-bottom: 80px;
    position: relative;
    width: 550px;
  }
  .Carusel_carusel__34ay5::before {
    width: 100%;
    height: 100%;
    content: "";
    background-color: #f49646;
    position: absolute;
    bottom: -15px;
    right: -15px;
    border-radius: 60px;
    z-index: -1;
    border: 4px solid #347eab;
  }
  .Carusel_carusel__34ay5 h4 {
    color: white;
    font-size: 1.75rem;
  }
  .Carusel_carusel__34ay5 p {
    color: white;
    font-size: 0.8rem;
    font-weight: 100;
  }
  .Carusel_carusel_img__3WT_2 {
    border-radius: 50%;
    overflow: hidden;
    width: 80px;
    height: 80px;
  }
}

@media only screen and (max-width: 576px) {
  .Carusel_carusel__34ay5 {
    background-color: #6fbdd6;
    border: 3px solid #347eab;
    border-radius: 40px;
    margin-bottom: 50px;
    position: relative;
    width: 300px;
  }
  .Carusel_carusel__34ay5::before {
    width: 100%;
    height: 100%;
    content: "";
    background-color: #f49646;
    position: absolute;
    bottom: -10px;
    right: -10px;
    border-radius: 40px;
    z-index: -1;
    border: 3px solid #347eab;
  }
  .Carusel_carusel__34ay5 h4 {
    color: white;
    font-size: 1rem;
  }
  .Carusel_carusel__34ay5 p {
    color: white;
    font-size: 0.8rem;
    font-weight: 100;
  }
  .Carusel_carusel_img__3WT_2 {
    border-radius: 50%;
    overflow: hidden;
    width: 75px;
    height: 75px;
  }
  .Carusel_carusel_txt__-kUPc {
    padding: 20px;
    padding-right: 20px;
  }
  .Carusel_picker__1NFex {
    padding: 25px 0 25px 35px;
  }
}

footer {
    background-color: #6fbdd6;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Footer_footer_logo_container__2_FwK {
    width: 100%;
    height: 150px;
    /*1*/
    background-color: #e8faff;
}

.Footer_footer_logo__2Q9n_ img {
    object-fit: cover;
}

.Footer_footer_logo__2Q9n_ {
    position: absolute;
    z-index: 1;
    background-color: white;
    border-radius: 50%;
    width: 300px;
    /*2*/
    height: 300px;
    overflow: hidden;
    border: 10px solid #65bed9;
}

footer h2 {
    color: white;
    font-size: 2rem;
    font-weight: bold;
    margin-top: 220px;
}

.Footer_footer_button__123FF {
    border-radius: 30px;
    border: none;
    color: white;
    background-color: #f49646;
    font-size: 3rem;
    margin: 40px 0;
    padding: 20px 20px;
}

@media only screen and (max-width:1200px) and (min-width: 991.9px) {
    .Footer_footer_logo_container__2_FwK {
        width: 100%;
        height: 125px;
        /*1*/
        background-color: #e8faff;
    }
    .Footer_footer_logo__2Q9n_ {
        position: absolute;
        z-index: 1;
        background-color: white;
        border-radius: 50%;
        width: 250px;
        /*2*/
        height: 250px;
        overflow: hidden;
        border: 8px solid #65bed9;
    }
    footer h2 {
        color: white;
        font-size: 1.8rem;
        font-weight: bold;
        margin-top: 180px;
    }
    .Footer_footer_button__123FF {
        border-radius: 30px;
        border: none;
        color: white;
        background-color: #f49646;
        font-size: 2.5rem;
        margin: 40px 0;
        padding: 20px 20px;
    }
}

@media only screen and (max-width: 991.9px) and (min-width: 768px) {
    .Footer_footer_logo_container__2_FwK {
        width: 100%;
        height: 75px;
        /*1*/
        background-color: #e8faff;
    }
    .Footer_footer_logo__2Q9n_ {
        position: absolute;
        z-index: 1;
        background-color: white;
        border-radius: 50%;
        width: 150px;
        /*2*/
        height: 150px;
        overflow: hidden;
        border: 6px solid #65bed9;
    }
    footer h2 {
        color: white;
        font-size: 1.5rem;
        font-weight: bold;
        margin-top: 120px;
    }
    .Footer_footer_button__123FF {
        border-radius: 30px;
        border: none;
        color: white;
        background-color: #f49646;
        font-size: 2rem;
        margin: 40px 0;
        padding: 20px 20px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
    .Footer_footer_logo_container__2_FwK {
        width: 100%;
        height: 70px;
        /*1*/
        background-color: #e8faff;
    }
    .Footer_footer_logo__2Q9n_ {
        position: absolute;
        z-index: 1;
        background-color: white;
        border-radius: 50%;
        width: 140px;
        /*2*/
        height: 140px;
        overflow: hidden;
        border: 5px solid #65bed9;
    }
    footer h2 {
        color: white;
        font-size: 1.2rem;
        font-weight: bold;
        margin-top: 100px;
    }
    .Footer_footer_button__123FF {
        border-radius: 25px;
        border: none;
        color: white;
        background-color: #f49646;
        font-size: 1.5rem;
        margin: 40px 0;
        padding: 20px 20px;
    }
}

@media only screen and (max-width: 576px) {
    .Footer_footer_logo_container__2_FwK {
        width: 100%;
        height: 50px;
        /*1*/
        background-color: #e8faff;
    }
    .Footer_footer_logo__2Q9n_ {
        position: absolute;
        z-index: 1;
        background-color: white;
        border-radius: 50%;
        width: 100px;
        /*2*/
        height: 100px;
        overflow: hidden;
        border: 4px solid #65bed9;
    }
    footer h2 {
        color: white;
        font-size: 0.8rem;
        font-weight: bold;
        margin-top: 80px;
    }
    .Footer_footer_button__123FF {
        border-radius: 25px;
        border: none;
        color: white;
        background-color: #f49646;
        font-size: 1rem;
        margin: 30px 0;
        padding: 20px 20px;
    }
}
.FooterBar_footer_bar__W9JO- {
    background-color: #347eab;
    width: 100%;
    height: 100px;
}

.FooterBar_footer_container__3yJ1q {
    /* min-width: 1300px; */
    display: flex;
    flex: row 1;
    justify-content: space-between;
    align-items: center;
}

.FooterBar_footer_bar__W9JO- p {
    color: white;
    margin: 0;
    font-size: 1rem;
    font-weight: 100;
    margin-right: 600px;
}

.FooterBar_footer_link__3RHZo {
    color: white;
    font-size: 1rem;
    font-weight: 100;
    text-decoration: none;
    position: relative;
    padding: 0 10px;
    margin-bottom: 0;
}

.FooterBar_footer_link__3RHZo:hover {
    color: white;
    text-decoration: none;
}

.FooterBar_footer_link__3RHZo::before {
    content: "";
    width: 2px;
    height: 50%;
    background-color: white;
    position: absolute;
    top: 55%;
    right: 0;
    -webkit-transform: translateY(-57%);
            transform: translateY(-57%);
}

.FooterBar_footer_link__3RHZo:last-child::before {
    display: none;
}

@media only screen and (max-width:1200px) and (min-width: 991.9px) {
    .FooterBar_footer_bar__W9JO- p {
        color: white;
        margin: 0;
        font-size: 1rem;
        font-weight: 100;
        margin-right: 500px;
    }
}

@media only screen and (max-width: 991.9px) and (min-width: 768px) {
    .FooterBar_footer_bar__W9JO- p {
        color: white;
        margin: 0;
        font-size: 1rem;
        font-weight: 100;
        margin-right: 200px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
    .FooterBar_footer_bar__W9JO- p {
        color: white;
        margin: 0;
        font-size: 1rem;
        font-weight: 100;
        margin-right: 75px;
    }
}

@media only screen and (max-width: 576px) {
    .FooterBar_footer_bar__W9JO- p {
        color: white;
        margin: 0;
        font-size: 0.5rem;
        font-weight: 100;
        margin-right: 20px;
    }
    .FooterBar_footer_link__3RHZo {
        color: white;
        font-size: 0.5rem;
        font-weight: 100;
        text-decoration: none;
        position: relative;
        padding: 0 10px;
        margin-bottom: 0;
    }
}
.ResultBox_result__lIxr- {
    font-size: 2rem;
    font-weight: 800;
    color: #347eab;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 100px;
}

.ResultBox_result__lIxr- h2 {
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    font-size: 4rem;
    font-weight: 400;
}

.ResultBox_result_box__3SanI {
    display: flex;
    justify-content: center;
    color: white;
    border-radius: 25px;
    background-color: #f79745;
    padding: 0 50px;
}

.ResultBox_price__3SXfs {
    font-size: 8rem;
    font-weight: 600;
    margin: 0;
}

@media only screen and (max-width: 991.9px) and (min-width: 768px) {
    .ResultBox_result__lIxr- {
        color: #347eab;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 100px;
    }
    .ResultBox_result__lIxr- h2 {
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
        font-size: 3rem;
        font-weight: 400;
    }
    .ResultBox_price__3SXfs {
        font-size: 6rem;
        font-weight: 600;
        margin: 0;
    }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
    .ResultBox_result__lIxr- {
        color: #347eab;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 100px;
    }
    .ResultBox_result__lIxr- h2 {
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
        font-size: 2.5rem;
        font-weight: 400;
    }
    .ResultBox_price__3SXfs {
        font-size: 5rem;
        font-weight: 600;
        margin: 0;
    }
}

@media only screen and (max-width: 576px) {
    .ResultBox_result__lIxr- {
        color: #347eab;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 85px;
    }
    .ResultBox_result__lIxr- h2 {
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        font-size: 1.5rem;
        font-weight: 400;
    }
    .ResultBox_price__3SXfs {
        font-size: 2rem;
        font-weight: 600;
        margin: 0;
    }
    .ResultBox_result_box__3SanI {
        display: flex;
        justify-content: center;
        color: white;
        border-radius: 8px;
        background-color: #f79745;
        padding: 0 50px;
    }
}
.SocialNetworks_socnet__3vwrp {
    margin-bottom: 80px;
}

.SocialNetworks_socnet__3vwrp h1 {
    font-size: 6rem;
    font-weight: 600;
    color: #347eab;
    text-align: center;
    margin-bottom: 20px;
}

.SocialNetworks_socnet__3vwrp img {
    width: 150px;
}

@media only screen and (max-width: 991.9px) and (min-width: 768px) {
    .SocialNetworks_socnet__3vwrp {
        margin-bottom: 70px;
    }
    .SocialNetworks_socnet__3vwrp h1 {
        font-size: 4rem;
        font-weight: 600;
        color: #347eab;
        text-align: center;
        margin-bottom: 20px;
    }
    .SocialNetworks_socnet__3vwrp img {
        width: 125px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
    .SocialNetworks_socnet__3vwrp {
        margin-bottom: 60px;
    }
    .SocialNetworks_socnet__3vwrp h1 {
        font-size: 3rem;
        font-weight: 600;
        color: #347eab;
        text-align: center;
        margin-bottom: 20px;
    }
    .SocialNetworks_socnet__3vwrp img {
        width: 100px;
    }
}

@media only screen and (max-width: 576px) {
    .SocialNetworks_socnet__3vwrp {
        margin-bottom: 80px;
    }
    .SocialNetworks_socnet__3vwrp h1 {
        font-size: 2rem;
        font-weight: 600;
        color: #347eab;
        text-align: center;
        margin-bottom: 20px;
    }
    .SocialNetworks_socnet__3vwrp img {
        width: 50px;
    }
}
.PrivacyPage_privacy__1yUV1 {
    
    padding: 10px 10vw 0 10vw;
}
