.footer_bar {
    background-color: #347eab;
    width: 100%;
    height: 100px;
}

.footer_container {
    /* min-width: 1300px; */
    display: flex;
    flex: row;
    justify-content: space-between;
    align-items: center;
}

.footer_bar p {
    color: white;
    margin: 0;
    font-size: 1rem;
    font-weight: 100;
    margin-right: 600px;
}

.footer_link {
    color: white;
    font-size: 1rem;
    font-weight: 100;
    text-decoration: none;
    position: relative;
    padding: 0 10px;
    margin-bottom: 0;
}

.footer_link:hover {
    color: white;
    text-decoration: none;
}

.footer_link::before {
    content: "";
    width: 2px;
    height: 50%;
    background-color: white;
    position: absolute;
    top: 55%;
    right: 0;
    transform: translateY(-57%);
}

.footer_link:last-child::before {
    display: none;
}

@media only screen and (max-width:1200px) and (min-width: 991.9px) {
    .footer_bar p {
        color: white;
        margin: 0;
        font-size: 1rem;
        font-weight: 100;
        margin-right: 500px;
    }
}

@media only screen and (max-width: 991.9px) and (min-width: 768px) {
    .footer_bar p {
        color: white;
        margin: 0;
        font-size: 1rem;
        font-weight: 100;
        margin-right: 200px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
    .footer_bar p {
        color: white;
        margin: 0;
        font-size: 1rem;
        font-weight: 100;
        margin-right: 75px;
    }
}

@media only screen and (max-width: 576px) {
    .footer_bar p {
        color: white;
        margin: 0;
        font-size: 0.5rem;
        font-weight: 100;
        margin-right: 20px;
    }
    .footer_link {
        color: white;
        font-size: 0.5rem;
        font-weight: 100;
        text-decoration: none;
        position: relative;
        padding: 0 10px;
        margin-bottom: 0;
    }
}