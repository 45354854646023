@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,900");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow-x: hidden;
  background-color: #e8faff;
  zoom: 0.75;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiPaper-root.MuiMenu-paper {
  left: 32px !important;
  top: 295px !important;
}
