.carusel_title {
  text-align: center;
  margin-top: 20px;
}

.wraper {
  display: flex;
  justify-content: center;
}

.carusel {
  background-color: #6fbdd6;
  border: 5px solid #347eab;
  border-radius: 60px;
  margin-bottom: 100px;
  position: relative;
  width: 1000px;
}

.carusel::before {
  width: 100%;
  height: 100%;
  content: "";
  background-color: #f49646;
  position: absolute;
  bottom: -20px;
  right: -20px;
  border-radius: 60px;
  z-index: -1;
  border: 5px solid #347eab;
}

.carusel_txt {
  padding: 20px;
  padding-right: 100px;
}

.carusel p {
  color: white;
  font-size: 1.5rem;
  font-weight: 100;
}

.carusel h4 {
  color: white;
  font-size: 2.5rem;
}

.carusel_img {
  border-radius: 50%;
  overflow: hidden;
  width: 125px;
  height: 125px;
}

.carusel_img img {
  object-fit: cover;
}

.picker {
  padding: 50px 0 50px 20px;
}

.picker p {
  margin: 0;
}

.circle_indicators li {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.circle_indicators {
  min-width: 50px;
  right: 30px;
  justify-content: center;
  margin: 0;
}

@media only screen and (max-width: 1200px) and (min-width: 991.9px) {
  .carusel {
    background-color: #6fbdd6;
    border: 5px solid #347eab;
    border-radius: 60px;
    margin-bottom: 100px;
    position: relative;
    width: 900px;
  }
  .carusel h4 {
    color: white;
    font-size: 2.25rem;
  }
  .carusel p {
    color: white;
    font-size: 1.25rem;
    font-weight: 100;
  }
}

@media only screen and (max-width: 991.9px) and (min-width: 768px) {
  .carusel {
    background-color: #6fbdd6;
    border: 5px solid #347eab;
    border-radius: 60px;
    margin-bottom: 80px;
    position: relative;
    width: 650px;
  }
  .carusel h4 {
    color: white;
    font-size: 2rem;
  }
  .carusel p {
    color: white;
    font-size: 1rem;
    font-weight: 100;
  }
  .carusel_img {
    border-radius: 50%;
    overflow: hidden;
    width: 100px;
    height: 100px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
  .carusel {
    background-color: #6fbdd6;
    border: 4px solid #347eab;
    border-radius: 60px;
    margin-bottom: 80px;
    position: relative;
    width: 550px;
  }
  .carusel::before {
    width: 100%;
    height: 100%;
    content: "";
    background-color: #f49646;
    position: absolute;
    bottom: -15px;
    right: -15px;
    border-radius: 60px;
    z-index: -1;
    border: 4px solid #347eab;
  }
  .carusel h4 {
    color: white;
    font-size: 1.75rem;
  }
  .carusel p {
    color: white;
    font-size: 0.8rem;
    font-weight: 100;
  }
  .carusel_img {
    border-radius: 50%;
    overflow: hidden;
    width: 80px;
    height: 80px;
  }
}

@media only screen and (max-width: 576px) {
  .carusel {
    background-color: #6fbdd6;
    border: 3px solid #347eab;
    border-radius: 40px;
    margin-bottom: 50px;
    position: relative;
    width: 300px;
  }
  .carusel::before {
    width: 100%;
    height: 100%;
    content: "";
    background-color: #f49646;
    position: absolute;
    bottom: -10px;
    right: -10px;
    border-radius: 40px;
    z-index: -1;
    border: 3px solid #347eab;
  }
  .carusel h4 {
    color: white;
    font-size: 1rem;
  }
  .carusel p {
    color: white;
    font-size: 0.8rem;
    font-weight: 100;
  }
  .carusel_img {
    border-radius: 50%;
    overflow: hidden;
    width: 75px;
    height: 75px;
  }
  .carusel_txt {
    padding: 20px;
    padding-right: 20px;
  }
  .picker {
    padding: 25px 0 25px 35px;
  }
}
