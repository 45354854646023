/* span {
    margin: 0 0.5rem;
} */

.ccc:hover {
    cursor: pointer;
}

.ww {
    width: 400px;
}

label {
    justify-content: center;
    display: flex;
    color: #9094a1;
    font-size: 1.2rem;
}

.st {
    position: static;
    margin-bottom: 11px;
}

.calc_title {
    /* min-width: 1200px; */
    color: #347eab;
    font-size: 25px;
    line-height: 1em;
}

.form_container {
    /* min-width: 1300px; */
    width: 1100px;
    padding: 20px;
    border-radius: 30px;
    margin: 0 20px 70px 20px;
    background-color: white;
    zoom: 1.2;
}

.input_border {
    border: none;
    outline: none;
    border-bottom: 2px solid #ebebeb;
    height: 50px;
    width: 100%;
    text-align-last: center;
}

.input_border::placeholder {
    color: black;
}

.calc_button {
    display: flex;
    justify-content: center;
    border: none;
    color: white;
    background-color: #008037;
    width: 100%;
    font-size: 20px;
    padding: 10px 35px;
}

@media only screen and (max-width:1200px) and (min-width: 991.9px) {
    .marg {
        margin: 10px 0;
    }
    .calc_title {
        color: #347eab;
        font-size: 20px;
        line-height: 1em;
    }
    .form_container {
        /* min-width: 1300px; */
        width: 950px;
        padding: 20px;
        border-radius: 30px;
        margin: 0 20px 70px 20px;
        background-color: white;
    }
    .ww {
        width: 375px;
    }
}

@media only screen and (max-width: 991.9px) and (min-width: 768px) {
    .calc_title {
        color: #347eab;
        font-size: 15px;
        line-height: 1em;
    }
    .mb {
        margin-bottom: 30px;
    }
    .date_con {
        width: 100%;
        height: 50px;
        border-bottom: 2px solid #ebebeb;
    }
    .form_container {
        min-width: 550px;
        width: 35%;
        padding: 20px;
        border-radius: 30px;
        margin-bottom: 8vh;
        background-color: white;
    }
    .input_border {
        border: none;
        outline: none;
        border-bottom: 2px solid #ebebeb;
        height: 50px;
        width: 240px;
    }
    .date_con {
        width: 240px;
        height: 50px;
        border-bottom: 2px solid #ebebeb;
    }
    .calc_button {
        border: none;
        color: white;
        background-color: #008037;
        width: 100%;
        font-size: 20px;
        margin: 25px 0px 15px;
        padding: 10px 35px;
    }
    .ww {
        width: 300px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
    .calc_title {
        color: #347eab;
        font-size: 13px;
        line-height: 1em;
    }
    .mb {
        margin-bottom: 15px;
    }
    label {
        justify-content: center;
        display: flex;
        color: #9094a1;
        font-size: 22px;
        margin: 0;
    }
    .marg {
        margin: 15px 0;
    }
    .date_con {
        width: 100%;
        height: 50px;
        border-bottom: 2px solid #ebebeb;
    }
    .form_container {
        /* min-width: 300px; */
        width: 70%;
        padding: 20px;
        border-radius: 30px;
        margin-bottom: 8vh;
        background-color: white;
    }
    .calc_button {
        border: none;
        color: white;
        background-color: #008037;
        width: 100%;
        font-size: 20px;
        margin: 25px 0px 15px;
        padding: 10px 35px;
    }
    .ww {
        width: 300px;
    }
}

@media only screen and (max-width: 576px) {
    .calc_title {
        color: #347eab;
        font-size: 13px;
        line-height: 1em;
    }
    .form_container {
        width: 300px;
        padding: 20px;
        border-radius: 30px;
        margin: 0 20px 70px 20px;
        background-color: white;
    }
    .tit {
        padding: 0px 30px;
    }
    .ww {
        width: 250px;
    }
}