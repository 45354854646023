footer {
    background-color: #6fbdd6;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footer_logo_container {
    width: 100%;
    height: 150px;
    /*1*/
    background-color: #e8faff;
}

.footer_logo img {
    object-fit: cover;
}

.footer_logo {
    position: absolute;
    z-index: 1;
    background-color: white;
    border-radius: 50%;
    width: 300px;
    /*2*/
    height: 300px;
    overflow: hidden;
    border: 10px solid #65bed9;
}

footer h2 {
    color: white;
    font-size: 2rem;
    font-weight: bold;
    margin-top: 220px;
}

.footer_button {
    border-radius: 30px;
    border: none;
    color: white;
    background-color: #f49646;
    font-size: 3rem;
    margin: 40px 0;
    padding: 20px 20px;
}

@media only screen and (max-width:1200px) and (min-width: 991.9px) {
    .footer_logo_container {
        width: 100%;
        height: 125px;
        /*1*/
        background-color: #e8faff;
    }
    .footer_logo {
        position: absolute;
        z-index: 1;
        background-color: white;
        border-radius: 50%;
        width: 250px;
        /*2*/
        height: 250px;
        overflow: hidden;
        border: 8px solid #65bed9;
    }
    footer h2 {
        color: white;
        font-size: 1.8rem;
        font-weight: bold;
        margin-top: 180px;
    }
    .footer_button {
        border-radius: 30px;
        border: none;
        color: white;
        background-color: #f49646;
        font-size: 2.5rem;
        margin: 40px 0;
        padding: 20px 20px;
    }
}

@media only screen and (max-width: 991.9px) and (min-width: 768px) {
    .footer_logo_container {
        width: 100%;
        height: 75px;
        /*1*/
        background-color: #e8faff;
    }
    .footer_logo {
        position: absolute;
        z-index: 1;
        background-color: white;
        border-radius: 50%;
        width: 150px;
        /*2*/
        height: 150px;
        overflow: hidden;
        border: 6px solid #65bed9;
    }
    footer h2 {
        color: white;
        font-size: 1.5rem;
        font-weight: bold;
        margin-top: 120px;
    }
    .footer_button {
        border-radius: 30px;
        border: none;
        color: white;
        background-color: #f49646;
        font-size: 2rem;
        margin: 40px 0;
        padding: 20px 20px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
    .footer_logo_container {
        width: 100%;
        height: 70px;
        /*1*/
        background-color: #e8faff;
    }
    .footer_logo {
        position: absolute;
        z-index: 1;
        background-color: white;
        border-radius: 50%;
        width: 140px;
        /*2*/
        height: 140px;
        overflow: hidden;
        border: 5px solid #65bed9;
    }
    footer h2 {
        color: white;
        font-size: 1.2rem;
        font-weight: bold;
        margin-top: 100px;
    }
    .footer_button {
        border-radius: 25px;
        border: none;
        color: white;
        background-color: #f49646;
        font-size: 1.5rem;
        margin: 40px 0;
        padding: 20px 20px;
    }
}

@media only screen and (max-width: 576px) {
    .footer_logo_container {
        width: 100%;
        height: 50px;
        /*1*/
        background-color: #e8faff;
    }
    .footer_logo {
        position: absolute;
        z-index: 1;
        background-color: white;
        border-radius: 50%;
        width: 100px;
        /*2*/
        height: 100px;
        overflow: hidden;
        border: 4px solid #65bed9;
    }
    footer h2 {
        color: white;
        font-size: 0.8rem;
        font-weight: bold;
        margin-top: 80px;
    }
    .footer_button {
        border-radius: 25px;
        border: none;
        color: white;
        background-color: #f49646;
        font-size: 1rem;
        margin: 30px 0;
        padding: 20px 20px;
    }
}