.wraper {
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 1180px;
    padding: 0 10px;
}

/* .main_image img {
    width: 70%;
    margin-bottom: 8vh;
} */

.site_content {
    padding: 0;
}

.site_content h2 {
    font-size: 2rem;
    font-weight: 650;
    color: #347eab;
    margin-bottom: 30px;
}

.site_content p {
    font-size: 1.5rem;
    color: #347eab;
    font-weight: 400;
}

.content_img {
    width: 500px;
}

.imgContainer {
    display: flex;
    justify-content: center;
}

/* @media only screen and (max-width: 991.9px) and (min-width: 200px) {
    .site_content p {
        font-size: 3vw;
        color: #347eab;
        font-weight: 400;
    }
    .site_content h2 {
        font-size: 4vw;
        font-weight: 650;
        color: #347eab;
        margin-bottom: 3vh;
        margin-top: 4vh;
    }
    .imgContainer {
        display: flex;
        justify-content: center;
        margin-bottom: 5vh;
    }
    .content_img {
        width: 50vw;
    }
} */

@media only screen and (max-width:1200px) and (min-width: 991.9px) {
    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 900px;
        padding: 0 10px;
    }
    .site_content h2 {
        font-size: 1.7rem;
        font-weight: 650;
        color: #347eab;
        margin-bottom: 30px;
    }
    .site_content p {
        font-size: 1.1rem;
        color: #347eab;
        font-weight: 400;
    }
    .content_img {
        width: 450px;
    }
}

@media only screen and (max-width: 991.9px) and (min-width: 768px) {
    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 850px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 600px;
    }
    .site_content h2 {
        font-size: 1.4rem;
        font-weight: 650;
        color: #347eab;
        margin-bottom: 30px;
    }
    .site_content p {
        font-size: 1rem;
        color: #347eab;
        font-weight: 400;
    }
    .content_img {
        width: 400px;
    }
}

@media only screen and (max-width: 576px) {
    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 300px;
    }
    .site_content h2 {
        font-size: 1.1rem;
        font-weight: 650;
        color: #347eab;
        margin-bottom: 30px;
    }
    .site_content p {
        font-size: 0.8rem;
        color: #347eab;
        font-weight: 400;
    }
    .content_img {
        width: 300px;
    }
}