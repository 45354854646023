header {
    margin: 0;
    padding: 0;
}

.padding_0 {
    padding-right: 0;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header_container {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: center;
}

.header_logo p {
    font-size: 1.35rem;
    line-height: 0.9em;
    color: #f49f55;
    margin: 0;
}

.header_logo img {
    width: 200px;
}

.header_title {
    font-size: 7rem;
    font-weight: 200;
    margin: 0;
    color: #6ebcd5;
    line-height: 0.9em;
    padding-top: 12px;
}

.header_subtitle {
    min-width: 675px;
    font-size: 2.75rem;
    line-height: 0.9em;
    font-weight: 350;
    margin: 0;
    color: #f49646;
    display: contents;
}

.last_title {
    min-width: 1200px;
    line-height: 1em;
    font-size: 3.7rem;
    font-weight: 400;
    color: #347eab;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width:1200px) and (min-width: 991.9px) {
    .last_title {
        min-width: 900px;
        line-height: 1em;
        font-size: 3.5rem;
        font-weight: 400;
        color: #347eab;
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 991.9px) and (min-width: 768px) {
    .header_logo p {
        font-size: 1rem;
        line-height: 0.9em;
        color: #f49f55;
        margin: 0;
    }
    .header_logo img {
        width: 150px;
    }
    .header_title {
        font-size: 5.5rem;
        font-weight: 200;
        margin: 0;
        color: #6ebcd5;
        line-height: 0.9em;
        padding-top: 12px;
    }
    .header_subtitle {
        min-width: 675px;
        font-size: 2rem;
        line-height: 0.9em;
        font-weight: 350;
        margin: 0;
        color: #f49646;
        display: contents;
    }
    .last_title {
        min-width: 750px;
        line-height: 1em;
        font-size: 2.75rem;
        font-weight: 400;
        color: #347eab;
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
    .header_logo p {
        font-size: 0.8rem;
        line-height: 0.9em;
        color: #f49f55;
        margin: 0;
    }
    .header_logo img {
        width: 110px;
    }
    .header_title {
        font-size: 4rem;
        font-weight: 200;
        margin: 0;
        color: #6ebcd5;
        line-height: 0.9em;
        padding-top: 8px;
    }
    .header_subtitle {
        min-width: 675px;
        font-size: 1.6rem;
        line-height: 0.9em;
        font-weight: 350;
        margin: 0;
        color: #f49646;
        display: contents;
    }
    .last_title {
        min-width: 550px;
        line-height: 1em;
        font-size: 2rem;
        font-weight: 400;
        color: #347eab;
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 576px) {
    .header_logo p {
        font-size: 0.4rem;
        line-height: 0.7em;
        color: #f49f55;
        margin: 0;
    }
    .header_logo img {
        width: 75px;
        padding-top: 10px;
    }
    .header_title {
        font-size: 2rem;
        font-weight: 200;
        margin: 0;
        color: #6ebcd5;
        line-height: 0.9em;
        padding-top: 12px;
    }
    .header_subtitle {
        min-width: 300px;
        font-size: 1rem;
        line-height: 0.9em;
        font-weight: 350;
        margin: 0;
        color: #f49646;
        display: contents;
    }
    .last_title {
        min-width: 300px;
        line-height: 1em;
        font-size: 1.2rem;
        font-weight: 400;
        color: #347eab;
        display: flex;
        justify-content: center;
    }
}