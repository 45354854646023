.socnet {
    margin-bottom: 80px;
}

.socnet h1 {
    font-size: 6rem;
    font-weight: 600;
    color: #347eab;
    text-align: center;
    margin-bottom: 20px;
}

.socnet img {
    width: 150px;
}

@media only screen and (max-width: 991.9px) and (min-width: 768px) {
    .socnet {
        margin-bottom: 70px;
    }
    .socnet h1 {
        font-size: 4rem;
        font-weight: 600;
        color: #347eab;
        text-align: center;
        margin-bottom: 20px;
    }
    .socnet img {
        width: 125px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
    .socnet {
        margin-bottom: 60px;
    }
    .socnet h1 {
        font-size: 3rem;
        font-weight: 600;
        color: #347eab;
        text-align: center;
        margin-bottom: 20px;
    }
    .socnet img {
        width: 100px;
    }
}

@media only screen and (max-width: 576px) {
    .socnet {
        margin-bottom: 80px;
    }
    .socnet h1 {
        font-size: 2rem;
        font-weight: 600;
        color: #347eab;
        text-align: center;
        margin-bottom: 20px;
    }
    .socnet img {
        width: 50px;
    }
}