.result {
    font-size: 2rem;
    font-weight: 800;
    color: #347eab;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 100px;
}

.result h2 {
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    font-size: 4rem;
    font-weight: 400;
}

.result_box {
    display: flex;
    justify-content: center;
    color: white;
    border-radius: 25px;
    background-color: #f79745;
    padding: 0 50px;
}

.price {
    font-size: 8rem;
    font-weight: 600;
    margin: 0;
}

@media only screen and (max-width: 991.9px) and (min-width: 768px) {
    .result {
        color: #347eab;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 100px;
    }
    .result h2 {
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
        font-size: 3rem;
        font-weight: 400;
    }
    .price {
        font-size: 6rem;
        font-weight: 600;
        margin: 0;
    }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
    .result {
        color: #347eab;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 100px;
    }
    .result h2 {
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
        font-size: 2.5rem;
        font-weight: 400;
    }
    .price {
        font-size: 5rem;
        font-weight: 600;
        margin: 0;
    }
}

@media only screen and (max-width: 576px) {
    .result {
        color: #347eab;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 85px;
    }
    .result h2 {
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        font-size: 1.5rem;
        font-weight: 400;
    }
    .price {
        font-size: 2rem;
        font-weight: 600;
        margin: 0;
    }
    .result_box {
        display: flex;
        justify-content: center;
        color: white;
        border-radius: 8px;
        background-color: #f79745;
        padding: 0 50px;
    }
}