audio::-webkit-media-controls-timeline,
video::-webkit-media-controls-timeline {
    display: none;
}
audio::-webkit-media-controls,
video::-webkit-media-controls {
    display: none;
}


.vvv {
    width: 1355px;
    margin: 0 20px;
}

.video_container {
    margin-bottom: 80px;
    margin-top: 40px;
}

@media only screen and (max-width:1200px) and (min-width: 991.9px) {
    .vvv {
        width: 1000px;
    }
}

@media only screen and (max-width: 991.9px) and (min-width: 768px) {
    .vvv {
        width: 800px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
    .vvv {
        width: 500px;
    }
}

@media only screen and (max-width: 576px) {
    .vvv {
        width: 250px;
    }
    .video_container {
        margin-bottom: 80px;
        margin-top: 20px;
    }
}