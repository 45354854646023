.main_image {
    display: flex;
    justify-content: center;
}

.main_image img {
    width: 1200px;
    margin-bottom: 80px;
    object-fit: cover;
}

@media only screen and (max-width:1200px) and (min-width: 991.9px) {
    .main_image img {
        width: 900px;
        margin-bottom: 70px;
    }
}

@media only screen and (max-width: 991.9px) and (min-width: 768px) {
    .main_image img {
        width: 700px;
        margin-bottom: 60px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
    .main_image img {
        width: 500px;
        margin-bottom: 60px;
    }
}

@media only screen and (max-width: 576px) {
    .main_image img {
        width: 250px;
        margin-bottom: 60px;
    }
}